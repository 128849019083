/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;

    padding: 7px;
    border-radius: 5px;
    background-color: rgb(247, 248, 250);
    color: black !important;
}

.navbar-brand {
    font-size: 1.5rem !important;
}

.scans-for-user-text {
    font-size: 1.25rem;
}

@media screen and (min-width: 600px) {
    .navbar-brand {
        font-size: 2rem !important;
    }

    .scans-for-user-text {
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 1000px) {
    .navbar-brand {
        font-size: 2.5rem !important;
    }

    .scans-for-user-text {
        font-size: 1.75rem;
    }
}

.display-linebreaks {
    white-space: pre-line;
}


.barcode-table {
    /* width: 100%; */
    /* margin: 20px 0px; */
    /* border-collapse: collapse; */
    /* border: 1px solid black !important; */
}

.barcode-table th {
    /* padding: 5px; */
    /* border: 1px solid black !important; */
}

.barcode-table td {
    /* padding: 5px; */
    /* border: 1px solid black !important; */
}

.barcode-image-modal {
    /* position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4); */
}

/* .configure-link-modal-container {
    
} */

/* .configure-link-modal {
    background-color: #cdcfd1;
} */

/* .configure-link-form { */
    /* padding: 20px; */
/* } */

.copy-button:active {
    /* box-shadow: 0 5px #666; */
    /* transform: translateY(4px); */
    /* background-color: darkblue !important; */
    /* background-color: lightblue !important; */
    /* background-color: blue !important; */
}

hr.padded-hr {
    width: 10% !important;
    margin: 40px auto !important;
}

.dropdown-toggle {
    background-color: rgb(157, 157, 157) !important;
    border: 1px solid black !important;
    width: 40px;
    height: 38px;
    margin: 5px !important;
    padding: 5px 0px !important;
}

.dropdown-toggle::after {
    display: none !important;
}

.username-form {
    /* backdrop-filter: blur(5px); */
    /* box-shadow: 30px 30px 30px 30px rgba(0, 0, 0, 0.5); */
}

.username-form-container {

    /* box-shadow: 10px 10px, 10px 10px rgba(0, 0, 0, 0.5); */

    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */

    box-shadow:
        rgba(0, 0, 0, 0.5) 5px 5px 50px 5px,
        rgba(0, 0, 0, 0.9) -7px -7px 0px inset;

}


.splash-text {
    font-size: 1.5rem;
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 0px;
    margin: 10px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    /* diagonal rotation */
    transform: rotate(-45deg);
}

/* Shine */
/* https://stackoverflow.com/questions/77507963/want-to-shine-effect-on-button-in-reactjs */

/*  
 -webkit-mask-image: linear-gradient(
     -75deg,
     rgba(0, 0, 0, 1) 30%,
     rgba(0, 0, 0, 1) 50%,
     rgba(0, 0, 0, 1) 70%
 );
 */

.shine {
    -webkit-mask-image: linear-gradient(
      -75deg,
      /* -90deg, */
      rgba(0, 0, 0, 1) 30%,
      /* rgba(0, 0, 0, 0.6) 50%, */
      rgba(0, 0, 0, 0.75) 50%,
      rgba(0, 0, 0, 1) 70%
    );
    -webkit-mask-size: 200%;
    animation: shine 2s linear infinite;
  }
    
  @keyframes shine {
    /* 0% {
      -webkit-mask-position: -50%;
    } */
    /* 25% {
      -webkit-mask-position: 150%;
    } */
    /* 50% {
        -webkit-mask-position: 200%;
    } */
    /* 75% {
      -webkit-mask-position: 150%;
    } */
    /* 100% {
      -webkit-mask-position: -50%;
    } */

    from {
        -webkit-mask-position: 150%;
    }
    to {
        -webkit-mask-position: -50%;
    }
    
  }

.scan-barcode-input {

}

.scan-barcode-container {

    box-shadow:
    /*  <offset-x> <offset-y> <blur-radius> <spread-radius> <color>*/
         0px        0px         40px          2px          rgba(0, 0, 0, 0.4);

    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* height: 100vh; */
    /* width: 100vw; */
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* position: fixed; */
    /* top: 0; */
    /* left: 0; */
    /* z-index: 100; */

}

.scan-barcode-submit-button {
    
}

.scan-barcode-submit-button:active {
    /* box-shadow: 0 5px #666; */
    background-color: rgb(234, 232, 232) !important;
    /* transform: translateY(4px); */
    /* background-color: darkblue !important; */
    /* background-color: lightblue !important; */
    /* background-color: blue !important; */
    
}
